<template>
  <div class="color-pickers">
    <b-form-group :label="attrs['label']">
      <b-input-group>
        <b-form-input
          size="lg"
          class="rounded-0"
          v-model="color"
          aria-describedby="input-1-live-feedback"
          @mousedown="toggle = !toggle"
        />
        <b-input-group-prepend is-text>
          <div class="color-selected" :style="'background-color:' + color" @click="toggle = !toggle" />
        </b-input-group-prepend>
      </b-input-group>
    </b-form-group>
    <chrome-picker class="color-picker-selected" :class="{ active: toggle }" :value="colors" @input="updateValue" />
  </div>
</template>

<script>
import { defineComponent, useAttrs, ref } from '@vue/composition-api';
import { Chrome } from 'vue-color';
export default defineComponent({
  components: {
    'chrome-picker': Chrome,
  },
  props: {
    color: {
      type: String,
    },
  },
  setup(props, context) {
    const expo = {};
    expo.attrs = useAttrs();

    expo.colors = ref('#000000');
    expo.toggle = ref(false);

    expo.updateValue = (event) => {
      context.emit('color', event.hex8);
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.color-pickers {
  position: relative;
  .color-selected {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .color-picker-selected {
    position: absolute;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    z-index: 100;
    &.active {
      opacity: 1;
      visibility: visible;
      transition: all 0.3s ease-in-out;
    }
  }
}
</style>
